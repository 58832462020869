// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../device';
import RenderMaps from '../units/home/maps';
import useInViewPort from '../hooks/hookLazyLoadMap';

// Styles
const Container = styled.section`
  grid-area: SVC;
  padding: 4.375rem 0;
  border-bottom: ${props =>
    props.isNotBorder ? 'unset' : '0.063rem solid #c6c8cc'};

  p {
    margin-bottom: ${props => props.isCDI && '1.875rem'};
    width: 16.875rem;
    line-height: 1.625rem;
    :first-child {
      display: none;
      em {
        font-style: normal;
        width: 100%;
      }
      @media ${device.laptop} {
        display: flex;
        width: 100%;
      }
    }

    //Bela Vista
    :nth-child(7) { 
      width: ${props => props.isCDI && '100%'};
    }

    //Itaim
    :nth-child(8) { 
      width: ${props => props.isCDI && '100%'};
    }

    @media ${device.laptop} {
      margin: 0;
      padding-bottom: 1.875rem;
      font-size: ${props => (props.isCDI ? '.875rem' : '1rem')};
      line-height: 1.5rem;
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
    }
  }

  em a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.375rem;
    padding: 1rem;
    font-size: 0.875rem;
    color: #45a7df;
    border: 0.063rem solid #c6c8cc;
    border-radius: 6.25rem;
    background: transparent;
    outline: none;
    :visited {
      color: #45a7df;
    }
    img {
      width: 1.5rem;
      height: 1rem;
      margin-right: 0.625rem;
    }

    @media ${device.mobile} {
      padding: 0.85rem;
    }
  }

  a {
    color: #45a7df;
    :visited {
      color: #45a7df;
    }
  }

  @media ${device.laptop} {
    padding: 1.875rem 0 0 0;
  }
`;

const ContainerContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.875rem;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 100%;
  }
`;

const Title = styled.h2`
  width: ${props => (props.isCDIBrasilia ? '17rem' : '20rem')};
  margin-bottom: 3.125rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.125rem;
  color: #231f20;

  @media ${device.laptop} {
    width: ${props => (props.isCDIBrasilia ? '12.5rem' : '15rem')};
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`;

const MapsMobile = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    width: 100%;
    height: 20.938rem;  
    margin-bottom: 1.875rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

const MarkdownContainer = styled.div`
  display: ${props => (props.isDiagnostico ? 'block' : 'flex')};
  flex-direction: ${props => props.isCDIBrasilia && 'column'};
  flex-wrap: ${props => (props.isCDIBrasilia ? 'unset' : 'wrap')};
  justify-content: space-between;

  h2 {
    padding-top: ${props =>
      props.isCDIBrasilia ? '3.125rem' : props.isCDI ? '1.938rem' : '0'}; 
    width: ${props => props.isCDI && '100%'};
    font-size: 1.5rem;
    font-weight: normal;
    color: #231f20;

    @media ${device.laptop} {
      padding-top: ${props =>
        props.isCDIBrasilia ? '1.25rem' : props.isCDI ? '1.75rem' : 'initial'};
    }
    font-size: 1.125rem;
    font-weight: ${props => props.isCDI && '400'};
  }

  h3 {
    font-weight: 400;
    color: #8f9194;
    font-size: 0.875rem;
    margin-bottom: inherit;
    line-height: 1.5rem;
  }

  p:nth-child(4) {
    width: ${props => props.isCDIBrasilia && '35rem'};

    @media ${device.laptop} {
      width: ${props => props.isCDIBrasilia && '100%'};
      padding-bottom: ${props => props.isCDIBrasilia && '1.25rem'};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    padding-right: ${props => props.isCDI && '0'};
  }
`;

const Maps = styled.div`
  display: flex;
  height: 35.625rem;
  img {
    object-fit: cover;
    width: 100%;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

function OpeningHours({
  markdown,
  fragments,
  zoom,
  location,
  photoMap,
  isCDI,
  isCDIBrasilia,
  newTab,
  isDiagnostico,
  isNotBorder,
}) {
  const { singletexts } = fragments && fragments[0];
  const [scriptReady, setScript] = useState(false);
  const [nodeRef, isVisible] = useInViewPort();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (isVisible) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&language=pt-BR`;
      script.addEventListener('load', () => {
        setScript(true);
      });
      document.body.appendChild(script);
    }
  }, [isVisible]);

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  return (
    <Container
      isCDI={isCDI}
      isCDIBrasilia={isCDIBrasilia}
      isNotBorder={isNotBorder}
      ref={nodeRef}
    >
      <ContainerContent>
        <Content>
          <Title isCDIBrasilia={isCDIBrasilia}>{singletexts}</Title>
          <MapsMobile>
            {!isMobile ? (
              <img src={photoMap} alt="Imagem do mapa" />
            ) : (
              <RenderMaps
                zoom={zoom}
                defaultCenter={location}
                location={location}
                scriptReady={scriptReady}
              />
            )}
          </MapsMobile>
          <MarkdownContainer
            isCDI={isCDI}
            isCDIBrasilia={isCDIBrasilia}
            isDiagnostico={isDiagnostico}
          >
            <ReactMarkdown
              renderers={newTab && { link: LinkRenderer }}
              children={markdown}
            />
          </MarkdownContainer>
        </Content>
        <Maps>
          <img src={photoMap} alt="Imagem do mapa" />
        </Maps>
      </ContainerContent>
    </Container>
  );
}

export default OpeningHours;
