// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from './device';

// Styles
const Container = styled.div`
  padding: 4.5rem 0 4.375rem 0;
  display: grid;
  grid-template-columns: repeat(12, 70px);
  grid-column: 1 / -1;
  column-gap: 30px;
  justify-content: left;
  flex-direction: column;
  border-bottom: 1px solid #c6c8cc;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    padding: 1.875rem 0;
  }
`;

const Content = styled.div`
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;
  grid-column: 7 / 13;

  h2 {
    display: none;

    @media ${device.laptop} {
      display: flex;
      margin-bottom: 1.875rem;
      width: 13rem;
      font-size: 1.5rem;
      font-weight: 400;
      color: #231f20;
    }

    @media ${device.tablet} {
      font-size: 1.125rem;
    }

    @media ${device.mobile} {
      line-height: 1.375rem;
    }
  }

  p {
    padding-bottom: 3.313rem;
    font-size: 1rem;
    line-height: 1.625rem;

    strong {
      color: #231f20;
    }

    a {
      display: flex;
      line-height: 2.625rem;
      color: #45a7df;
      align-items: center;

      @media ${device.laptop} {
        line-height: 1.625rem;
      }

      img {
        height: 26px;
        width: 26px;
      }
    }

    :last-child {
      padding-bottom: 0;
    }

    @media ${device.laptop} {
      padding-bottom: 1.875rem;
      font-size: 1rem;
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
    }
  }
`;

const Title = styled.div`
  display: grid;
  grid-column: 1 / 7;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }

  h2 {
    width: 18rem;
    font-size: 1.5rem;
    font-weight: 400;

    @media ${device.laptop} {
      display: none;
    }
  }

  p {
    display: none;
  }
`;

const TwoColumns = ({ markdown }) => (
  <Container>
    <Title>
      <ReactMarkdown children={markdown || '-'} />
    </Title>
    <Content>
      <ReactMarkdown children={markdown || '-'} />
    </Content>
  </Container>
);

export default TwoColumns;
