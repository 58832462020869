// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import GraphImg from 'graphcms-image';

// Components
import { device } from '../device';

// Styles
const Container = styled.section`
  margin: 6.25rem 0;
  position: relative;
  display: flex;

  p {
    width: 35.625rem;
    margin-bottom: 1.875rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    color: #231f20;

    @media ${device.laptop} {
      padding-bottom: 1.875rem;
      margin-bottom: 0;
      width: fit-content;
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
    }

    :last-child {
      margin-bottom: 0;
    }

    strong {
      color: #231f20;
    }
  }

  @media ${device.laptop} {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ContentText = styled.div`
  margin-right: 1.875rem;
`;

const ContainerImg = styled.div`
  display: flex;
  justify-content: flex-end;

  .Img {
    padding-bottom: 1.875rem;
    width: 35.625rem;
    height: 23.75rem;

    @media ${device.laptop} {
      padding-bottom: 1.25rem;
      padding-right: 1.875rem;
      width: 60.625rem;
      height: 38.75rem;
    }

    @media ${device.tablet} {
      width: 45.5rem;
      height: 29rem;
    }

    @media ${device.mobile} {
      width: 24.1rem;
      height: 15rem;
    }

    @media ${device.mobile375} {
      width: 21rem;
    }

    @media ${device.mobile320} {
      width: 17.6rem;
      height: 12rem;
    }
  }

  @media ${device.laptop} {
    position: unset;
    justify-content: center;
    padding-bottom: 2.875rem;
  }

  @media ${device.tablet} {
    padding-bottom: ${props => props.isCDIItaim && '3.875rem'};
  }

  @media ${device.mobile} {
    padding-bottom: 1.875rem;
  }
`;

const ImgBlob = styled.img`
  position: absolute;
  top: 283px;
  right: -33px;
  width: 15.875rem;
  height: 12.345rem;
  transform: rotate(8deg);
  z-index: -1;

  @media ${device.laptop} {
    top: 578px;
    right: -15px;
  }

  @media ${device.tablet} {
    transform: translate(0, -14%) rotate(8deg);
    top: 414px;
    left: 485px;
  }

  @media ${device.mobile} {
    top: 159px;
    left: 143px;
  }

  @media ${device.mobile375} {
    left: 98px;
  }

  @media ${device.mobile320} {
    top: 116px;
    left: 37px;
  }
`;

const Title = styled.h2`
  width: 23.875rem;
  padding-top: 2.3rem;
  margin-bottom: 3.125rem;
  font-weight: 400;
  color: #231f20;
  line-height: 1.875rem;

  @media ${device.laptop} {
    width: ${props => props.isCDIItaim && '25.875rem'};
    margin-bottom: 1.875rem;
  }

  @media ${device.tablet} {
    padding-top: 0;
  }

  @media ${device.mobile320} {
    width: ${props => props.isCDIItaim && '19rem'};
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    line-height: normal;

    @media ${device.tablet} {
      font-size: 1.125rem;
    }
  }
`;

const MarkdownContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    display: none;
  }

  p {
    width: 35.625rem;
    :first-child {
      width: 34.625rem;
    }
  }
`;

const MarkdownContainer2 = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

function TechnicalExcellence({ markdown, fragments, asset, blob, isCDIItaim }) {
  const { singletexts } = fragments && fragments[0];

  return (
    <Container>
      <ContentText>
        <Title isCDIItaim={isCDIItaim}>
          <p>{singletexts[0]}</p>
          <p>{singletexts[1]}</p>
        </Title>
        <MarkdownContainer>
          <ReactMarkdown children={markdown} />
        </MarkdownContainer>
      </ContentText>
      <ContainerImg isCDIItaim={isCDIItaim}>
        <GraphImg
          image={asset}
          className="Img"
          alt="Calor Humano"
          fadeIn={true}
          withWebp={true}
        />
        <ImgBlob src={blob} alt="Pink Blob" />
      </ContainerImg>
      <MarkdownContainer2>
        <ReactMarkdown children={markdown} />
      </MarkdownContainer2>
    </Container>
  );
}

export default TechnicalExcellence;
