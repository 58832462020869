// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Services from 'components/units/unitPages/unit/services';
import TechnicalExcellence from 'components/cdi/technicalExcellence';
import WhyChooseOurCDI from 'components/cdi/WhyChooseOurCDI';
import ListPosts from 'components/blog/listPosts';
import OpeningHours from 'components/cdi/openingHours';
import DiscoverOurDifferentials from 'components/twoColumns';
import OthersCids from 'components/cdi/othersCdis';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Image
import firstBlob from 'images/blobs/Slime_Purple.webp';
import secBlob from 'images/blobs/Slime_Pink.webp';
import MapImage from 'images/mapsImages/cdi-bela-vista.png';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'auto')};
  margin: ${props => (props.isMarginPart ? props.isMarginPart : 'none')};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;

    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const components = page[0]?.components ?? [];
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('9.063rem');
      setMainMarginTopMobile('7.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / span 12'} isCDIUnits>
          {featuredMarketingContent && (
            <FeaturedMarketing
              content={featuredMarketingContent}
              isPadding={'00.195rem'}
            />
          )}
        </Part>
        <Part
          gridColumn={'2 / -2'}
          Bottom={'2.5rem'}
          isNotBorder
          isFitCardButtonPages
          borderBottom
          marginTop={'2.5rem'}
        >
          <Services
            markdown={getMarkdown(page, 'ckksndnps2wpg0977pj6hhnng', true)}
            fragments={getMarkdown(page, 'ckksndnps2wpg0977pj6hhnng')}
            cardHowCanIHelp
            noJustifyContent
            isSlideBelaVista
            isCDIUnits
            titleDeskWith="100%"
            isAdjustmentInCssLayout
            isAdjustmentInCssLayoutMobile
            marginLeftCards="1.3rem"
            marginLeftCardslap="1.5rem"
            marginLeftCardsMob="0"
            marginRightCard="30px"
            widthTextHiperlink
            isCentroDiagnostico
            isFitCardButtonPages
          />
        </Part>
        <Part gridColumn={'2 / -2'} Bottom={'2.5rem'} isNotBorder>
          <TechnicalExcellence
            markdown={getMarkdown(page, 'ckksnqrxs2ygp0a73yzjnnhbc', true)}
            fragments={getMarkdown(page, 'ckksnqrxs2ygp0a73yzjnnhbc')}
            asset={getMarkdown(page, 'ckksnqrxs2ygp0a73yzjnnhbc', false, true)}
            blob={secBlob}
          />
        </Part>
        <Part gridColumn={'1 / -1'}>
          <WhyChooseOurCDI
            markdown={getMarkdown(page, 'ckkssdfxs3v0o0977ybvm6650', true)}
            fragments={getMarkdown(page, 'ckkssdfxs3v0o0977ybvm6650')}
          />
        </Part>
        <Part gridColumn={'2 / -2'}>
          <DiscoverOurDifferentials
            markdown={getMarkdown(page, 'ckksos3aw3a130972ryvwq8am', true)}
            isBorder
          />
        </Part>
        <Part
          gridColumn={'2 / -2'}
          Bottom={'2.5rem'}
          isNotBorder
          id="opening-hours"
        >
          <OpeningHours
            markdown={getMarkdown(page, 'ckksq3to03hgh0a732evaersd', true)}
            fragments={getMarkdown(page, 'ckksq3to03hgh0a732evaersd')}
            zoom={15}
            location={{ lat: -23.5575613, lng: -46.6545421 }}
            photoMap={MapImage}
            isCDI
          />
        </Part>
        <Part
          gridColumn={'1 / -1'}
          isNotBorder
          isMarginFullWidth
          isMarginPart={'-1.363rem'}
        >
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding borderTop>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const CDIBelaVista = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);
  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  // ====== WEBCHAT ====== //
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705/assets/js/bootstrap.min.js';
    document.body.appendChild(script);

    const handleScriptLoad = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'pt_BR';
        window.embeddedservice_bootstrap.init(
          '00D41000000exwd',
          'Webchat_Agendamento_Sites',
          'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705',
          {
            scrt2URL: 'https://hospitalsiriolibanes.my.salesforce-scrt.com',
          }
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    };

    script.addEventListener('load', handleScriptLoad);

    return () => {
      script.removeEventListener('load', handleScriptLoad);
      document.body.removeChild(script);

      [
        'embedded-messaging',
        'embeddedMessagingSiteContextFrame',
        'embeddedMessagingFilePreviewFrame',
      ].forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          document.body.removeChild(element);
        }
      });
    };
  }, []);

  const getImage = getMarkdown(page, 'ckkslohk026w80972ipvv6b5o', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckkslfwww26y80977fqwebtn6" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <CDIBelaVista
            page={response.gcms.site.pages}
            location={props.location}
            {...props}
          />
        );
      }}
    />
  );
};
